import React, { useState } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import { Router, Location } from "@reach/router"
import { useSearchParam, useMount } from 'react-use';

import { Layout, Footer, PrivateRoute } from '../components'
import { container, padding, bgImage, hoverState } from '../styles/global'
import { fetchData } from '../services/api';

import Index from '../pages/index'
import DefaultPage from '../templates/default'
import Person from '../templates/person'
import Service from '../templates/service'
import OurStory from '../pages/our-story'
import Expertise from '../pages/expertise'
import Team from '../pages/team'
import RecentTransactions from '../pages/recent-transactions'
import Contact from '../pages/contact'

const Preview = (props) => {
	const [data, setData] = useState(null);
    const [postType, setPostType] = useState(null);
	const postID = useSearchParam('id');

	useMount(() => {
		fetchData(`/pda/preview/${postID}`, true, true)
			.then(response => {				
				let data = response;
								
				setData(data)
				setPostType(data.post_type)
			})
	})
	
	const resolveTemplate = () => {
		if (!postType || !data) return;

		const templates = {
            'page-home': Index,
            'page-default': DefaultPage,
            'page-our-story': OurStory,
            'page-recent-transactions': RecentTransactions,
            'page-expertise': Expertise,
            'page-team': Team,
            'page-contact': Contact,   
            'team': Person,
            'service': Service
		}

		const Component = templates[postType];

		return (
			<Component
                previewData={data}
                pageContext={data}
			/>
		)
	}
	
	return (
		<>
			{resolveTemplate()}
		</>
	)
}

const PreviewRouter = () => {
	return (
		<Router>
			<Preview 
				path={`/preview`} 
			/>
		</Router>
	)
}

export default PreviewRouter

