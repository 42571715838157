import React, { useState, Fragment } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { motion } from 'framer-motion'

import { Layout, Block } from '../components'
import { Fade } from '../utils'

import { container, padding, bgImage, bgIcon } from '../styles/global'
import { media, useBreakpoint } from '../styles/utils'
import {
    heading1,
    heading2,
    subheading2,
    heading3,
    links,
    body,
    subheading1,
    caption,
} from '../styles/type'
import {
    brown1,
    brown3,
    blue2,
    brown4,
    brown2,
    blue1,
    blue3,
    blue4,
} from '../styles/colors'

import arrow from '../assets/images/icons/arrow-blue.svg'


const DefaultPage = ({ pageContext, ...props }) => {
    console.log('data', pageContext)

    const { acf } = pageContext


    const renderBlocks = () => {
        if (!acf.blocks) return

        console.log('acf.blocks',acf.blocks);

        return acf.blocks.map((block, i) => {
            return (
                <Block
                    layout={block.acf_fc_layout}
                    key={i} 
                    {...block}
                />
            )
        })
    }
    
    const renderContent = () => {
        return (
            <Content>
                <Fade delay={1200}>
                    <Container>
                        <Grid>
                            <Sidebar>
                                <Subheading>{pageContext.title}</Subheading>
                            </Sidebar>

                            <Main>
                                <Heading>{pageContext.title}</Heading>  
                                
                                { renderBlocks() } 

                            </Main>
                        </Grid>
                    </Container>
                </Fade>
            </Content>
        )
    }




    return (
        <Layout 
            meta={acf && acf.seo}
            desktopHeaderTheme={'dark'}
        >

            {renderContent()}

        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const MobileHeading = styled.h2``
const Subheading = styled.h2``
const Description = styled.div``
const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Grid = styled.div``
const Sidebar = styled.div``
const Main = styled.div``
const Container = styled.div`
    ${container}
    ${padding}

    ${Container} > ${Grid} {
        display: flex;
        max-width: 100%;

        ${media.phone`
            flex-direction: column;
        `}
    }

    ${Sidebar}, ${Main} {
        box-sizing: border-box;
    }

    ${Sidebar} {
        width: 33.33%;

        ${media.phone`
            width: 100%;
        `}
    }

    ${Main} {
        width: 66.66%;

        ${media.phone`
            width: 100%;
        `}
    }
`

// Animations

const animatedPosition = {
    variants: {
        hover: {
            y: '-12px',
        },
    },
    transition: { duration: 0.3 },
}

// Hero Image

const Hero = styled.div`
    position: relative;
    height: 880px;

    ${media.tabletLandscape`
        height: 500px;
    `}

    ${media.phone`
        height: 100vh;
    `}

    ${Image},
    ${BGImage} {
        height: 100%;
    }

    ${BGImage} {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: black;
    }
`

// Content
const Content = styled.div`
    margin-top: -150px;
    background-color: ${blue3};

    ${media.tabletLandscape`
        margin-top: -100px;
    `}

    ${media.phone`
        margin-top: 0;
    `}

    ${Sidebar}, ${Main} {
        padding-top: 320px;

        ${media.tabletLandscape`
            padding-top: 200px;
        `}

        ${media.phone`
            padding-top: 100px;
        `}
    }

    ${Sidebar} {
        ${Subheading} {
            ${heading3};
            color: white;
        }

        ${media.phone`
            display: none;
        `}
    }

    ${Main} {
        position: relative;
        padding-left: 20px;
        padding-bottom: 150px;
        border-left: 1px solid ${blue2};

        ${media.tabletLandscape`
            padding-bottom: 100px;
        `}

        ${media.phone`
            padding-bottom: 32px;
            padding-left: 0;
            border-left: 0;
        `}
    }

    ${Heading} {
        position: absolute;
        top: 0;
        left: 20px;
        ${heading1};
        color: white;
        line-height: 1;

        ${media.phone`
            position: static;
            color: ${blue1};
        `}
    }

    ${MobileHeading} {
        margin-top: 96px;
        ${heading3};
        color: white;
    }

    ${Description} {
        ${body};
        color: ${blue1};

        ${media.phone`
            margin-top: 30px;
        `}

        a {
            color: ${blue2};
            transition: color 0.15s ease;

            &:hover {
                color: ${brown2};
            }
        }
    }
`


export default DefaultPage
